<template>
    <div class="grid">
        <q-icon 
            v-show="!show_menu"
            class="no-desktop trigger-menu-button" 
            name="fas fa-chevron-right"
            color="primary"
            size="xl"
            @click="show_menu = true"
        />
        <q-icon 
            v-show="show_menu"
            class="no-desktop trigger-menu-button" 
            name="fas fa-chevron-left"
            color="primary"
            size="xl"
            @click="show_menu = false"
        />
        <nav :class="show_menu ? 'nav-visible' : ''">
            <div class="account">
                <label class="dashboard-nav-heading" v-html="resource[lang].account" />
                <router-link class="dashboard-nav-link" to="/account/account-properties" v-html="resource[lang].account_properties" />
                <router-link class="dashboard-nav-link" to="/account/change-password" v-html="resource[lang].change_password" />
                <router-link class="dashboard-nav-link" to="/account/delete-account" v-html="resource[lang].delete_account" />
            </div>
            <div class="project" v-if="admin || super_user || learning_module_editor">
                <label class="dashboard-nav-heading" v-html="resource[lang].project_area" />
                <router-link 
                    v-if="admin || super_user || learning_module_editor"
                    class="dashboard-nav-link" 
                    to="/account/learning-modules" 
                    v-html="resource[lang].learning_modules" 
                />
                <router-link 
                    v-if="admin || super_user || learning_module_editor"
                    class="dashboard-nav-link" 
                    to="/account/files" 
                    v-html="resource[lang].files" 
                />
                <router-link 
                    v-if="admin || super_user"
                    class="dashboard-nav-link" 
                    to="/account/users" 
                    v-html="resource[lang].users" 
                />
                <router-link 
                    v-if="admin || super_user" 
                    class="dashboard-nav-link" 
                    to="/account/user-roles" 
                    v-html="resource[lang].user_roles" 
                />
            </div>
        </nav>
        <div class="main">
            <router-view :key="$route.fullPath" v-slot="{ Component }">
                <transition name="fade">
                    <component :is="Component" />
                </transition>
            </router-view>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import resource from '@/assets/resources/account-resource'

import { getAbility } from '@/assets/js/user-abilities' 

export default {
    title: 'Planungsmatrix - Account',
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            admin: false,
            super_user: false,
            learning_module_editor: false,

            // responsiveness
            show_menu: false,

            resource
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    created() {
        this.getAbilities();
    },
    methods: {
        // Init
        async getAbilities() {
            this.admin = await getAbility('admin', this.user);
            this.super_user = await getAbility('super-user', this.user);
            this.learning_module_editor = await getAbility('learning-module-editor', this.user);
        }
    },
    watch: {
        user: function() {
            this.getAbilities();
        }
    },
    // check if user is logged in before enter
    beforeRouteEnter(to, from, next) {
        const token = localStorage.getItem('jwt_token');

        if (token) {
            next()
        } else {
            next({name: 'Login'})
        }
    },
}
</script>

<style lang="scss" scoped>

nav { 
    
    a {
        display: block;
    }
    
    div > * + * {
        margin-block-start: 1rem;
    }

    label {
        margin-block-end: 2.5rem;
    }

    div {
        margin-block-end: 5rem;
    }
}

.grid {
    grid-template-columns: 1fr 3fr;
    gap: 1rem;
}

@media (max-width: 64rem) {

    nav {
        position: fixed;
        left: -25rem;

        padding-top: 2rem;
        padding-left: 5rem;
        margin-left: -5rem;
        background-color: var(--whit);
        border-right: solid 1px var(--dark_grey);
        border-bottom: solid 1px var(--dark_grey);
        border-top: solid 1px var(--dark_grey);

        width: 25rem;

        z-index: 1;

        transition: left 1s;

        max-height: 67vh;
        overflow-y: auto;
    }

    .nav-visible {
        left: 3rem;
    }

    .grid {
        display: block;
    }

    .trigger-menu-button {
        position: fixed;
        left: -0.5rem;
        top: 10rem;
        z-index: 1;
        background-color: var(--light_grey);
        padding: 1rem 1.5rem;
        border-radius: 10px;
        border: solid 1px var(--dark_grey);
    }
}

@media (max-width: 40rem) {
    .trigger-menu-button {
        font-size: 2rem !important;
        padding: 0.75rem 1rem;
        border-radius: 5px;
    }
}

</style>