const resource = {
    en: {
        account: 'Account',
        account_properties: 'Account Properties',
        change_password: 'Change Password',
        delete_account: 'Delete Account',
        project_area: 'Project Area',
        learning_modules: 'Learning Modules',
        files: 'Files',
        users: 'Users',
        user_roles: 'User Roles',
    },
    de: {
        account: 'Profil',
        account_properties: 'Profileigenschaften',
        change_password: 'Passwort ändern',
        delete_account: 'Profil löschen',
        project_area: 'Projektbereich',
        learning_modules: 'Lernmodule',
        files: 'Dateien',
        users: 'Benutzer*innen',
        user_roles: 'Benutzerrollen',
    }
}

export default resource;